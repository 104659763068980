.header {
  display: flex;
  justify-content: space-between;
  height: 7rem;
  align-items: center;
  justify-content: right;
  h5 {
    color: var(--grey-light);
  }
  @media screen and (max-width: 960px) {
    height: 4rem;
  }
}

.appBar {
  z-index: calc(#{var(--z-index-modal)} + 1);
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: var(--height-app-bar-mobile);
  justify-content: space-between;
  padding: 12px 16px;
  background-color: white;
  box-shadow: 1px 1px 9px 0px rgb(0 0 0 / 18%);
}

.leftContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-left: inherit;
}

.brandLogoContainer {
  align-items: center;
  display: flex;
  height: var(--height-app-bar-mobile);
  justify-content: center;

  img {
    height: 38px;
    width: auto;
  }
}

.menuLogoContainer {
  svg {
    height: 32px;
    width: 32px;
    color: var(--secondary)
  }
}

.lePassLogoNavMobile {
  height: 48px;
  width: auto;
}

.betaBannerMobile {
  background-color: red;
  color: white;
  padding: 5px 50px;
  z-index: 2;
  transform: rotate(315deg);
  transform-origin: left top;
  font-size: 1.6rem;
  font-weight: 600;
  position: absolute;
  bottom: -28px;
  left: -40px;
}